import { Spin } from "antd";
import { userApis } from "apis";
import { StoreContext } from "contexts";
import { flagHooks, userHooks } from "hooks";
import { cloneDeep } from "lodash";
import { IRoute } from "models";
import React, { useEffect } from "react";
import { iframeUtils } from "utils";
import { getAuthorizedSellerIds } from "utils/seller";
import Route from "./Route";

const PrivateRoute = ({ component: Component, ...rest }: IRoute) => {
  useEffect(() => {
    const userId = userApis.getUserInfo()?.sub;
    // Tracking - Set userId when logged in
    track("setUserId", userId);

    // Tracking - Enable content impressions
    track("enableTrackVisibleContentImpressions");
    return () => {
      // Tracking - Disable content impressions
      track("disableTrackVisibleContentImpressions");
    };
  }, []);

  // Check if user is logged in or not
  if (!userApis.isLoggedIn()) {
    userApis.login();
    return null;
  }

  // Fetch global data
  const { currentUser } = userHooks.useUserInfo();
  const authorizedSellerIds = getAuthorizedSellerIds(currentUser);

  const currentUserParams = currentUser ? cloneDeep(currentUser) : undefined;

  if (currentUserParams?.meta_data) {
    currentUserParams.meta_data.seller_id = authorizedSellerIds[0]?.toString() || "";
  }

  const { featureFlagsData, isInitiating } = flagHooks.useFeatureFlags(currentUserParams);

  // Show spin when fetching required global data
  if (!currentUser || isInitiating) {
    return <Spin className="app-spin" />;
  }

  return (
    <StoreContext.Provider
      value={{
        inIframe: iframeUtils.inIframe(),
        currentUser,
        featureFlagsData,
      }}
    >
      <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
    </StoreContext.Provider>
  );
};

export default PrivateRoute;
