import { RequestFlagKeyData } from "flagsup-ts";
import { IUserDetailsInfo } from "teko-oauth2";

const FLAG_KEYS = {
  // epic flag keys
  SPLIT_CONSUMER_AND_BACKOFFICE_TENANT: "epic_omni_1121",
  UNIFY_ORDER_STATUS_LABEL: "epic_omni_1058",
  REDESIGN_FLOW_MANUAL_SELLERS: "epic_omni_1244",
  ALLOW_SELLER_SETUP_ORDER_NOTIFICATION: "epic_ecom_295",
  CONFIRM_ORDER_BY_SITE: "epic_omni_1251",
  AUTO_CREATE_SR_FOR_ITEMS: "epic_omni_1301",
  GET_USER_TERMINALS: "epic_ecom_432",

  // non-epic flag keys
  REFRESH_WITH_GUARANTEE_OMPRODUCT_1052: "REFRESH_WITH_GUARANTEE_OMPRODUCT_1052",
  REFRESH_WITH_GUARANTEE_OMPRODUCT_1171: "REFRESH_WITH_GUARANTEE_OMPRODUCT_1171",
  SHOW_SURVEY_POPUP_OMPRODUCT_1714: "SHOW_SURVEY_POPUP_OMPRODUCT_1714",
  CALL_BFF_CATALOG_OMPRODUCT_1100: "CALL_BFF_CATALOG_OMPRODUCT_1100",
  BUTTON_EXPORT_DATA_IN_ALL_TAB_OMPRODUCT_1924: "BUTTON_EXPORT_DATA_IN_ALL_TAB_OMPRODUCT_1924",
  IMPROVE_BILLING_INFO_EDIT_HWF_1482: "IMPROVE_BILLING_INFO_EDIT_HWF_1482",
};

// Extend flag keys
// Flag defined within this object will be treated as a flag that requires entityId to be evaluated
// The flag will be evaluate with logic:
// 1. If the flag is enabled => return true
// 2. If the flag is disabled => check if the entityId is in the whitelist => return true
// 3. If the flag is disabled and entityId is not in the whitelist => return false
type RequestFlagKeysWithCustomEntityId = {
  [flagKey in ExtendFlagKeys]: RequestFlagKeyData<IUserDetailsInfo>;
};

// Define the flag keys in order to support suggestion and auto-complete in IDE
type ExtendFlagKeys =
  | "SPLIT_ORDER_BFF_LIST_FOR_PLATFORM"
  | "SPLIT_ORDER_BFF_LIST_FOR_SELLER"
  | "SPLIT_ORDER_BFF_DETAIL_FOR_PLATFORM"
  | "SPLIT_ORDER_BFF_DETAIL_FOR_SELLER"
  | "MANAGE_OFFLINE_STORE_FOR_SELLER"
  | "SITE_LIST_WITH_ACTIVE_STATUS"
  | "EPIC_OMNI_1277"
  | "EPIC_OMNI_1399"
  | "EPIC_ECOM_489"
  | "USE_LOCATION_GH"
  | "EPIC_OMNI_1504"
  | "EPIC_OMNI_1407"
  | "EPIC_OMNI_1462"
  | "SEARCH_SELLER_ORDER_WITH_EXPORT_SITE";

const EXTENDED_FLAG_KEYS: RequestFlagKeysWithCustomEntityId = {
  SPLIT_ORDER_BFF_LIST_FOR_PLATFORM: {
    key: "omni_1018_order_list_for_platform",
    getEntityId: (userDetail) => String(userDetail?.meta_data?.platformId),
  },
  SPLIT_ORDER_BFF_LIST_FOR_SELLER: {
    key: "omni_1018_order_list_for_seller",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  SPLIT_ORDER_BFF_DETAIL_FOR_PLATFORM: {
    key: "omni_1018_order_detail_for_platform",
    getEntityId: (userDetail) => String(userDetail?.meta_data?.platformId),
  },
  SPLIT_ORDER_BFF_DETAIL_FOR_SELLER: {
    key: "omni_1018_order_detail_for_seller",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  MANAGE_OFFLINE_STORE_FOR_SELLER: {
    key: "epic_omni_595_seller",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  SITE_LIST_WITH_ACTIVE_STATUS: {
    key: "epic_omni_1139",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  EPIC_OMNI_1277: {
    key: "epic_omni_1277",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  EPIC_OMNI_1399: {
    key: "epic_omni_1399",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  EPIC_ECOM_489: {
    key: "epic_ecom_489",
    entityId: "om_product",
  },
  USE_LOCATION_GH: {
    key: "use_location_gh",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  EPIC_OMNI_1504: {
    key: "epic_omni_1504",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  EPIC_OMNI_1407: {
    key: "epic_omni_1407",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  EPIC_OMNI_1462: {
    key: "epic_omni_1462",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
  SEARCH_SELLER_ORDER_WITH_EXPORT_SITE: {
    key: "search_seller_order_with_export_site",
    getEntityId: (userDetail) => userDetail?.meta_data?.seller_id,
  },
};

export default {
  FLAG_KEYS,
  EXTENDED_FLAG_KEYS,
};
