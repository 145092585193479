import { notification } from "antd";
import { PaginationProps } from "antd/es/pagination";
import { catalogApis, locationApis, warehouseApis } from "apis";
import { commonConstants, flagConstants, manualInventoryConstants } from "constants/index";
import { StoreContext } from "contexts";
import { uniq } from "lodash";
import lodashDebounce from "lodash/debounce";
import {
  ActiveStatus,
  FulfillmentProviderMap,
  IAreaV2,
  IFulfillmentProvider,
  IOrderItem,
  IShippingType,
  ISiteV2,
  ISiteV2GetListFilter,
  ISitesBySeller,
  IsLoadingWarehousesBySeller,
  OrderItemSource,
  ProvinceMap,
  Shipment,
  WarehousesBySeller,
} from "models";
import { COUNTRY, ILocation } from "models/location";
import { OptionData } from "rc-select/lib/interface";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "utils/i18n";

const { DEBOUNCE_WAITING_TIME, ENTERPRISE_WAREHOUSE_PROVIDER, DEFAULT_PAGINATION } = commonConstants;
const { FLAG_KEYS, EXTENDED_FLAG_KEYS } = flagConstants;
const { ACTIVE_STATUS_MAP } = manualInventoryConstants;

const DEFAULT_PROVINCE_GH_PARAMS = { country: COUNTRY.GH };

const useSiteData = (siteId?: ISiteV2["id"]) => {
  const { featureFlagsData } = useContext(StoreContext);
  const isUseLocationGH = featureFlagsData[EXTENDED_FLAG_KEYS.USE_LOCATION_GH.key]?.enabled;
  const history = useHistory();
  const [fulfillmentProviders, setFulfillmentProviders] = useState<IFulfillmentProvider[]>([]);
  const [provinces, setProvinces] = useState<IAreaV2[]>([]);
  const [districts, setDistricts] = useState<IAreaV2[]>([]);
  const [wards, setWards] = useState<IAreaV2[]>([]);
  const [site, setSite] = useState<ISiteV2>();
  const [loading, setLoading] = useState(false);

  const provinceParams = isUseLocationGH ? DEFAULT_PROVINCE_GH_PARAMS : undefined;

  const getInitialCreateData = () => {
    setLoading(true);
    Promise.all([locationApis.getProvincesOrEmptyV2(provinceParams), warehouseApis.getFulfillmentProvidersOrEmpty()])
      .then((results) => {
        setProvinces(results[0]);
        setFulfillmentProviders(results[1]);
      })
      .finally(() => setLoading(false));
  };

  const getInitialUpdateData = (siteId: ISiteV2["id"]) => {
    setLoading(true);
    warehouseApis
      .getSitesV2({ siteId: siteId, includeConfigShippingType: true })
      .then((sites) => {
        const siteData = sites[0];
        if (!!siteData) {
          setSite(siteData);
          return Promise.allSettled([
            locationApis.getProvincesOrEmptyV2(provinceParams),
            isUseLocationGH
              ? Promise.resolve([])
              : locationApis.getDistrictsOrEmptyV2(siteData.locationId?.substring(0, 2)),
            isUseLocationGH
              ? Promise.resolve([])
              : locationApis.getWardsOrEmptyV2(siteData.locationId?.substring(0, 4)),
            warehouseApis.getFulfillmentProvidersOrEmpty(),
          ]);
        } else {
          return Promise.reject();
        }
      })
      .then(([provinceRes, districtRes, wardRes, fulfillmentProvidersRes]) => {
        provinceRes.status === "fulfilled" && setProvinces(provinceRes.value);
        districtRes.status === "fulfilled" && setDistricts(districtRes.value);
        wardRes.status === "fulfilled" && setWards(wardRes.value);
        fulfillmentProvidersRes.status === "fulfilled" && setFulfillmentProviders(fulfillmentProvidersRes.value);
      })
      .catch(() => {
        notification.error({
          message: t("Error"),
          description: t("ApiMessages.Common.NotFound"),
        });
        history.push("/404");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    !!siteId ? getInitialUpdateData(siteId) : getInitialCreateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const deactivateSite = async (siteId: ISiteV2["id"]) => {
    try {
      await warehouseApis.deactivateSite(siteId);
      notification.success({ message: t("DeactivateSiteSuccessMsg") });
      getInitialUpdateData(siteId);
    } catch (error) {}
  };

  return {
    site,
    loading,
    fulfillmentProviders,
    locationData: {
      provinces,
      districts,
      wards,
      setDistricts,
      setWards,
    },
    setLoading,
    getInitialUpdateData,
    deactivateSite,
  };
};

const useSiteList = () => {
  const { featureFlagsData } = useContext(StoreContext);
  const isOmni1139Enabled = featureFlagsData[EXTENDED_FLAG_KEYS.SITE_LIST_WITH_ACTIVE_STATUS.key]?.enabled;
  const isUseLocationGH = featureFlagsData[EXTENDED_FLAG_KEYS.USE_LOCATION_GH.key]?.enabled;

  const [filters, setFilters] = useState<ISiteV2GetListFilter>({
    isActive: isOmni1139Enabled ? ActiveStatus.ACTIVE : undefined,
    "paging.page": DEFAULT_PAGINATION.current,
    "paging.pageSize": DEFAULT_PAGINATION.pageSize,
  });
  const [tableData, setTableData] = useState<ISiteV2[]>([]);
  const [provinceMap, setProvinceMap] = useState<ProvinceMap>({});
  const [provinces, setProvinces] = useState<IAreaV2[]>([]);
  const [ffProviderMap, setFfProviderMap] = useState<FulfillmentProviderMap>({});
  const [isFetchingSites, setIsFetchingSites] = useState(false);
  const [isFetchingProvinces, setIsFetchingProvinces] = useState(false);
  const isFirstRender = useRef(true);
  const [pagination, setPagination] = useState<
    PaginationProps & {
      hasMore?: boolean;
    }
  >({
    ...DEFAULT_PAGINATION,
  });

  const getSites = async (
    filters: ISiteV2GetListFilter,
    provinceMap: ProvinceMap,
    ffProviderMap: FulfillmentProviderMap
  ) => {
    try {
      setIsFetchingSites(true);
      let data = await warehouseApis.getSitesV2({
        ...filters,
        isActive: filters.isActive ? ACTIVE_STATUS_MAP[filters.isActive] : undefined,
      });
      if (data) {
        if (Object.keys(provinceMap).length !== 0) {
          data = data.map((site) => {
            const fulfillmentProviderName =
              ffProviderMap[site.fulfillmentProviderId] || ffProviderMap[ENTERPRISE_WAREHOUSE_PROVIDER];
            return { ...site, city: provinceMap[site.city], fulfillmentProviderName };
          });
        }
        const isFirstPage = filters["paging.page"] === DEFAULT_PAGINATION.current;

        setTableData((prev) => (isFirstPage ? data : [...prev, ...data]));
        setPagination((prev) => ({
          ...prev,
          current: (filters["paging.page"] || 0) + 1,
          pageSize: filters["paging.pageSize"],
          hasMore: data.length === DEFAULT_PAGINATION.pageSize,
        }));
      }
    } finally {
      setIsFetchingSites(false);
    }
  };

  const getLocations = async (): Promise<ProvinceMap> => {
    const provinceCodeToName: ProvinceMap = {};

    try {
      setIsFetchingProvinces(true);
      const provinceParams = isUseLocationGH ? DEFAULT_PROVINCE_GH_PARAMS : undefined;
      const data = await locationApis.getProvincesOrEmptyV2(provinceParams);

      if (data.length !== 0) {
        setProvinces(data);
        data.forEach((province: IAreaV2) => {
          if (province) provinceCodeToName[isUseLocationGH ? province.id : province.code] = province.name;
        });
        setProvinceMap(provinceCodeToName);
      }
    } finally {
      setIsFetchingProvinces(false);
      return provinceCodeToName;
    }
  };

  const getFulfillmentProviders = async (): Promise<FulfillmentProviderMap> => {
    const ffProviderIdToName: FulfillmentProviderMap = {};

    try {
      const data = await warehouseApis.getFulfillmentProvidersOrEmpty();
      data.forEach((ffProvider) => {
        if (ffProvider) ffProviderIdToName[String(ffProvider.id)] = ffProvider.name;
      });
      setFfProviderMap(ffProviderIdToName);
    } finally {
    }
    return ffProviderIdToName;
  };

  const onLoadMore = () => {
    const { current } = pagination;
    setFilters((prev) => ({ ...prev, "paging.page": current }));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      const getInitialData = async () => {
        const initialProvinceMap = await getLocations();
        const ffProviderMap = await getFulfillmentProviders();
        getSites(filters, initialProvinceMap || {}, ffProviderMap || {});
      };
      getInitialData();
      isFirstRender.current = false;
    } else {
      if (isFetchingSites) return;
      getSites(filters, provinceMap, ffProviderMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    filters,
    tableData,
    provinces,
    isFetchingSites,
    isFetchingProvinces,
    isOmni1139Enabled,
    isUseLocationGH,
    pagination,
    setFilters,
    onLoadMore,
  };
};

const useSites = () => {
  const { featureFlagsData } = useContext(StoreContext);
  const is595ForSellerEnabled = featureFlagsData[EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key]?.enabled;

  const [sites, setSites] = useState<ISiteV2[]>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const getSites = async () => {
      setFetching(true);
      try {
        if (is595ForSellerEnabled) {
          const res = await warehouseApis.getSitesV2();
          if (!!res?.length) {
            setSites(res);
          }
        } else {
          const res = await warehouseApis.getSites();
          if (!!res.data.records?.length) {
            setSites(res.data.records);
          }
        }
      } finally {
        setFetching(false);
      }
    };

    getSites();
  }, [is595ForSellerEnabled]);

  return {
    sites,
    fetching,
  };
};

const useWarehouseBySellerPre1018 = (orderItems: IOrderItem[]) => {
  const [warehousesBySeller, setWarehousesBySeller] = useState<WarehousesBySeller>({});
  const [isLoadingWarehousesBySeller, setIsLoadingWarehousesBySeller] = useState<IsLoadingWarehousesBySeller>({});

  const getWarehouses = async (sellerId: IOrderItem["sellerId"]) => {
    if (!warehousesBySeller[sellerId]) {
      const { data } = await warehouseApis.getWarehouses({ sellerId });
      return data.records;
    }
    return warehousesBySeller[sellerId];
  };

  useEffect(() => {
    if (orderItems[0]?.source !== OrderItemSource.OC) {
      const sellerIds = uniq(orderItems.map((item) => item.sellerId));
      if (!!sellerIds.length) {
        sellerIds.forEach(async (sellerId) => {
          setIsLoadingWarehousesBySeller((prevState) => ({ ...prevState, [sellerId]: true }));
          try {
            const warehouse = await getWarehouses(sellerId);
            setWarehousesBySeller((prevState) => ({ ...prevState, [sellerId]: warehouse }));
          } catch (error) {
          } finally {
            setIsLoadingWarehousesBySeller((prevState) => ({ ...prevState, [sellerId]: false }));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems]);

  return {
    warehousesBySeller,
    setWarehousesBySeller,
    isLoadingWarehousesBySeller,
    setIsLoadingWarehousesBySeller,
  };
};

const useWarehouseBySeller = (shipments: Shipment[]) => {
  const [warehousesBySeller, setWarehousesBySeller] = useState<WarehousesBySeller>({});
  const [isLoadingWarehousesBySeller, setIsLoadingWarehousesBySeller] = useState<IsLoadingWarehousesBySeller>({});

  const getWarehouses = async (sellerId: Shipment["sellerId"]) => {
    if (!warehousesBySeller[sellerId]) {
      const { data } = await warehouseApis.getWarehouses({ sellerId });
      return data.records;
    }
    return warehousesBySeller[sellerId];
  };

  useEffect(() => {
    const sellerIds = uniq(shipments.map((item) => item.sellerId));
    if (!!sellerIds.length) {
      sellerIds.forEach(async (sellerId) => {
        setIsLoadingWarehousesBySeller((prevState) => ({ ...prevState, [sellerId]: true }));
        try {
          const warehouse = await getWarehouses(sellerId);
          setWarehousesBySeller((prevState) => ({ ...prevState, [sellerId]: warehouse }));
        } catch (error) {
        } finally {
          setIsLoadingWarehousesBySeller((prevState) => ({ ...prevState, [sellerId]: false }));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments]);

  return {
    warehousesBySeller,
    setWarehousesBySeller,
    isLoadingWarehousesBySeller,
    setIsLoadingWarehousesBySeller,
  };
};

const useShippingTypes = () => {
  const [shippingTypes, setShippingTypes] = useState<IShippingType[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [defaultShippingType, setDefaultShippingType] = useState<IShippingType | null>(null);
  const { featureFlagsData } = useContext(StoreContext);
  const isCallBffCatalog = featureFlagsData[FLAG_KEYS.CALL_BFF_CATALOG_OMPRODUCT_1100]?.enabled;

  useEffect(() => {
    const fetchShippingTypes = async () => {
      try {
        setIsFetching(true);
        let respShippingTypes: IShippingType[] = [];
        let isFetchAll = false;
        let currentPage = 1;
        if (isCallBffCatalog) {
          while (!isFetchAll) {
            const resp = await catalogApis.getShippingTypesBFF({ pageSize: 50, page: currentPage });
            respShippingTypes = [...respShippingTypes, ...resp.shippingTypes];
            currentPage++;
            if (respShippingTypes.length >= resp.pagination.total) {
              isFetchAll = true;
            }
          }
        } else {
          while (!isFetchAll) {
            const resp = await catalogApis.getShippingTypesOrEmpty({ pageSize: 50, page: currentPage });
            respShippingTypes = [...respShippingTypes, ...resp.shippingTypes];
            currentPage++;
            if (respShippingTypes.length >= resp.totalRecords) {
              isFetchAll = true;
            }
          }
        }
        setShippingTypes(respShippingTypes);
      } finally {
        setIsFetching(false);
      }
    };

    fetchShippingTypes();
  }, [isCallBffCatalog]);

  useEffect(() => {
    if (shippingTypes.length > 0) {
      const shippingType = shippingTypes.find((shippingType) => shippingType.isDefault);
      setDefaultShippingType(!!shippingType ? shippingType : null);
    }
  }, [shippingTypes]);

  return {
    shippingTypes,
    defaultShippingType,
    isFetching,
  };
};

const useLocations = () => {
  const [searchLocationOptions, setSearchLocationOptions] = useState<OptionData[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchLocations = async (searchText: string) => {
    try {
      setIsFetching(true);
      const resp = await locationApis.getLocationsOrEmpty(searchText.trim());
      setSearchLocationOptions(transform2LocationOption(resp));
    } finally {
      setIsFetching(false);
    }
  };

  const debounceLocationSearch = lodashDebounce((searchText: string) => {
    if (searchText) {
      fetchLocations(searchText);
    }
  }, DEBOUNCE_WAITING_TIME);

  return {
    isFetching,
    searchLocationOptions,
    debounceLocationSearch,
  };
};

const transform2LocationOption = (locationData: ILocation[]): OptionData[] => {
  return locationData.map((value) => {
    let code: string;
    if (value?.wardCode) {
      code = value.wardCode;
    } else if (value?.districtCode) {
      code = value.districtCode;
    } else {
      code = value.provinceCode;
    }
    return {
      value: code,
      label: value.fullName,
    };
  });
};

const useSitesBySeller = (shipments: Shipment[], isActive?: boolean) => {
  const [sitesBySeller, setSitesBySeller] = useState<ISitesBySeller>({});
  const [isLoadingSitesBySeller, setIsLoadingSitesBySeller] = useState<IsLoadingWarehousesBySeller>({});

  const getSites = async (sellerId: Shipment["sellerId"]) => {
    if (!sitesBySeller[sellerId]) {
      const sites = await warehouseApis.getSellerSites(sellerId, {
        isActive,
      });

      return sites?.map((item) => {
        return {
          id: item.id,
          name: [item.code, item.name].filter(Boolean).join(" - "),
          code: item.code,
        };
      });
    }
    return sitesBySeller[sellerId];
  };

  useEffect(() => {
    const sellerIds = uniq(shipments.map((item) => item.sellerId));

    if (sellerIds.length) {
      sellerIds.forEach(async (sellerId) => {
        setIsLoadingSitesBySeller((prevState) => ({ ...prevState, [sellerId]: true }));
        try {
          const sites = await getSites(sellerId);

          setSitesBySeller((prevState) => ({ ...prevState, [sellerId]: sites }));
        } catch (error) {
        } finally {
          setIsLoadingSitesBySeller((prevState) => ({ ...prevState, [sellerId]: false }));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments]);

  return {
    sitesBySeller,
    isLoadingSitesBySeller,
  };
};

export default {
  useSiteData,
  useSites,
  useSiteList,
  useSitesBySeller,
  useWarehouseBySellerPre1018,
  useWarehouseBySeller,
  useShippingTypes,
  useLocations,
};
